import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

function ApplicationDetails() {
    const { applicationId } = useParams();
    const [application, setApplication] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [emailOrUsername, setEmailOrUsername] = useState(""); // Input field for adding members or owners
    const [userRole, setUserRole] = useState("member"); // Role for user (admin or member)
    const [serviceId, setServiceId] = useState(""); // Input for new serviceId
    const [operationError, setOperationError] = useState(""); // Error for operations

    useEffect(() => {
        const fetchApplicationDetails = async () => {
            try {
                const response = await axios.get(`/application/${applicationId}`);
                setApplication(response.data.application);
            } catch (error) {
                setError("Could not fetch application details");
            } finally {
                setLoading(false);
            }
        };

        fetchApplicationDetails();
    }, [applicationId]);

    const handleAddUser = async () => {
        try {
            await axios.post(`/application/${applicationId}/add-user`, {
                emailOrUsername,
                role: userRole,
            });
            setOperationError(""); // Clear error on success
            // Refresh application details after adding user
            const response = await axios.get(`/application/${applicationId}`);
            setApplication(response.data.application);
        } catch (error) {
            setOperationError("Error adding user");
        }
    };

    const handleRemoveUser = async (userId, role) => {
        try {
            await axios.post(`/application/${applicationId}/remove-user`, {
                userId,
                role,
            });
            setOperationError(""); // Clear error on success
            // Refresh application details after removing user
            const response = await axios.get(`/application/${applicationId}`);
            setApplication(response.data.application);
        } catch (error) {
            setOperationError("Error removing user");
        }
    };

    const handleAddService = async () => {
        try {
            await axios.post(`/application/${applicationId}/add-service`, {
                serviceId,
            });
            setOperationError(""); // Clear error on success
            // Refresh application details after adding service
            const response = await axios.get(`/application/${applicationId}`);
            setApplication(response.data.application);
        } catch (error) {
            setOperationError("Error adding service");
        }
    };

    const handleRemoveService = async (serviceIdToRemove) => {
        try {
            await axios.delete(
                `/application/${applicationId}/remove-service/${serviceIdToRemove}`
            );
            setOperationError(""); // Clear error on success
            // Refresh application details after removing service
            const response = await axios.get(`/application/${applicationId}`);
            setApplication(response.data.application);
        } catch (error) {
            setOperationError("Error removing service");
        }
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <h2>Application Details</h2>
            <p>Application Name: {application.applicationName}</p>
            <p>Admins: {application.admins.map((admin) => admin.username).join(", ")}</p>
            <p>Members: {application.members.map((member) => member.username).join(", ")}</p>
            <p>Allowed Services: {application.approvedServicesId.join(", ")}</p>

            {/* Add/Remove Users */}
            <h3>Add or Remove Users</h3>
            <input
                type="text"
                value={emailOrUsername}
                onChange={(e) => setEmailOrUsername(e.target.value)}
                placeholder="Enter email or username"
            />
            <select value={userRole} onChange={(e) => setUserRole(e.target.value)}>
                <option value="admin">Admin</option>
                <option value="member">Member</option>
            </select>
            <button onClick={handleAddUser}>Add User</button>
            {operationError && <p style={{ color: "red" }}>{operationError}</p>}

            <h4>Admins</h4>
            <ul>
                {application.admins.map((admin) => (
                    <li key={admin._id}>
                        {admin.username}{" "}
                        <button onClick={() => handleRemoveUser(admin._id, "admin")}>
                            Remove Admin
                        </button>
                    </li>
                ))}
            </ul>

            <h4>Members</h4>
            <ul>
                {application.members.map((member) => (
                    <li key={member._id}>
                        {member.username}{" "}
                        <button onClick={() => handleRemoveUser(member._id, "member")}>
                            Remove Member
                        </button>
                    </li>
                ))}
            </ul>

            {/* Add/Remove Allowed Services */}
            <h3>Add or Remove Allowed Services</h3>
            <input
                type="text"
                value={serviceId}
                onChange={(e) => setServiceId(e.target.value)}
                placeholder="Enter service ID"
            />
            <button onClick={handleAddService}>Add Service</button>
            <ul>
                {application.approvedServicesId.map((serviceId) => (
                    <li key={serviceId}>
                        {serviceId}{" "}
                        <button onClick={() => handleRemoveService(serviceId)}>
                            Remove Service
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default ApplicationDetails;
